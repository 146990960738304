import React, { useMemo } from "react";
import { Widget } from "@typeform/embed-react";

import { LayoutBox } from "../../../components/atoms/LayoutBox";
import Layout from "../../../components/Layout";

const OnboardingThankYou = (props) => {
  const searchParams = useMemo(
    () => new URLSearchParams(props.location.search),
    [props.location.search]
  );
  const onboarding_id = searchParams.get("id");

  return (
    <Layout title="Thank you!">
      <LayoutBox>
        <div className="columns is-centered">
          <a
            href="https://calendly.com/ellis-neumind/neumind-demo-call"
            className={`button is-rounded is-medium is-primary is-text-centered`}
            target="_blank"
            rel="noreferrer"
          >
            Sign up
          </a>
        </div>
        <Widget
          id="ksG8rGB0"
          autoResize={true}
          inlineOnMobile={true}
          hidden={{ onboarding_id }}
        />
      </LayoutBox>
    </Layout>
  );
};

export default OnboardingThankYou;
